/* Hide the scrollbar by default */
.scrollable::-webkit-scrollbar {
  width: 0; /* No vertical scrollbar */
  height: 0; /* No horizontal scrollbar */
  background: transparent; /* Make the scrollbar transparent */
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background: transparent; /* Make the thumb transparent */
}

/* Show scrollbar on hover */
.scrollable:hover::-webkit-scrollbar {
  width: 2px; /* Width of the vertical scrollbar */
  height: 2px; /* Height of the horizontal scrollbar */
  background: transparent; /* Make the scrollbar transparent */
}

/* Show track on hover */
.scrollable:hover::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Show thumb on hover */
.scrollable:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Change thumb color on hover */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  height: 41px;
}
