body {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  background-color: #ffff;
}
.aggrement-form {
  margin: 8px;
}
.heading1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000000;
  font-weight: bold;
}

.table-aggrement {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table-aggrement td,
.table-aggrement th {
  padding: 10px;
  border: 1px solid #ccc;
  color: #000000;
  background-color: #f5f5f5;
}

.total {
  text-align: right;
}

.company-details {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.company-details p {
  margin: 0;
  flex: 1 1 50%;
  font-weight: bold;
  color: #000000;
}
.aggrement-form p {
  margin: 0;
  flex: 1 1 50%;
  color: #000000;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 2;
}

.aggrement-form-b {
  font-weight: bold;
  color: #000000;
}

.company-details span {
  flex: 1 1 50%;
  text-align: right;
  color: #000000;
}
.para p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
